import React, { useState, Suspense, lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styles from './RecipientPage.module.css';
import RecipientSelect from './RecipientSelect';
import CreateRecipientModal from './CreateRecipientModal';
import { useCart, useDispatchCart } from '../../../context/CartContext';

import { useAuth } from '../../../context/AuthContext';

const CartSummary = lazy(() => import('../cartitem/CartSummary'));

const RecipientPage = () => {
  const { authState } = useAuth();
  const cart = useCart();
  const dispatchCart = useDispatchCart();
  const [showModal, setShowModal] = useState(false);
  // const [message, setMessage] = useState("");
  // const [detailsVisible, setDetailsVisible] = useState(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (cart) {
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart]);

  const handleModalClose = (success = false) => {
    setShowModal(false);
    if (success) {
      toast.success('Recipient created successfully! You can now select them from the dropdown.', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    queryClient.invalidateQueries(['recipients', authState?.id]);
  };

  const handleRecipientSelect = (recipientId, productRecipient) => {
    console.log('recipientId', recipientId);
    console.log('productRecipient', productRecipient);
    dispatchCart({
      type: 'UPDATE_CART_RECIPIENTS',
      payload: { recipientId, productRecipient },
    });
  };

  return (
    <div className={styles.recipientContainer}>
      <div className={styles.recipientMainSection}>
        <div className={styles.recipientHeader}>
          <h1>Recipient</h1>
          <button className={styles.createNewButton} onClick={() => setShowModal(true)}>
            + Create new recipient
          </button>
        </div>

        <p className={styles.recipientSubtext}>Create a recipient or select from your saved recipients</p>

        <div className={styles.selectSection}>
          <label>Select recipient</label>
          <RecipientSelect onSelect={handleRecipientSelect} />
        </div>
      </div>

      <Suspense fallback={<div className={styles.loading}>Loading cart summary...</div>}>
        <CartSummary buttonText="Continue to Payment" />
      </Suspense>

      {showModal && (
        <CreateRecipientModal
          onClose={() => handleModalClose()}
          onSuccess={() => handleModalClose(true)}
          userId={authState.id}
        />
      )}
    </div>
  );
};

export default RecipientPage;
